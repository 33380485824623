import React from "react";
import './Projects.css'

const Projects = ({ selectedDescription }) => {
  return (
    <>
    </>

  );
};

export default Projects;
